

import asyncOperations  from '@/client/extensions/composition/asyncOperations.js';




export default (store) => {
    store.asyncOperations = asyncOperations({}, store);
}
